/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import AboutArticle from "~components/AboutArticle";
import SEO from "~components/SEO";

const AboutPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  const { frontmatter } = data.markdownRemark;

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="about-page w-full relative overflow-x-hidden">
        <div className="grid pb-12 xs:pb-0">
          <article className="grid-end-6 sm:grid-end-10 xs:grid-end-12 sm:grid-start-2 xs:grid-start-1 relative text-left">
            <h1
              className={`about-page__banner__heading w-full relative whitespace-pre-wrap xs:whitespace-normal ${
                device === `desktop` ? `f3` : `f2`
              }`}
            >
              {frontmatter.heading}
            </h1>

            <p className="w-3/4 xs:w-full mt-12 xs:mt-8 whitespace-pre-wrap b1">
              {frontmatter.intro}
            </p>
          </article>

          <div className="grid-end-6 sm:grid-end-12 relative block xs:mt-24">
            <figure className="about-page__bleed offgrid-right offgrid-right--no-touch">
              <Img
                className="w-full relative block"
                fluid={frontmatter.bannerImage.childImageSharp.fluid}
                alt="Tea in a pot"
              />
            </figure>
          </div>
        </div>

        <ul className="relative pb-24 xs:pb-12">
          {frontmatter.articleBlocks.map((articleBlock, articleBlockIndex) => {
            const key = `article-block-${articleBlockIndex}`;

            return (
              <li key={key}>
                <AboutArticle
                  align={articleBlock.align}
                  bordered={
                    articleBlockIndex === frontmatter.articleBlocks.length - 1
                  }
                  content={articleBlock.content}
                  heading={articleBlock.heading}
                  image={articleBlock.image}
                  small
                />
              </li>
            );
          })}
        </ul>
      </Layout>

      <Footer />
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        articleBlocks {
          align
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          heading
          content
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
