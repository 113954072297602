import React, { useContext } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";

const AboutArticle = ({ align, className, content, heading, image }) => {
  const { device } = useContext(DocumentContext);

  //

  return (
    <section
      className={`${className} w-full relative pt-12 xs:pt-0 pb-12 xs:pb-0 border-t-black xs:border-none`}
    >
      <article className="grid">
        {device === `desktop` && align === `left` && (
          <div className="grid-end-6 sm:grid-end-12 pr-v2 xs:pr-0">
            <div className="about-page__bleed relative">
              <AppearOnScroll>
                <Img
                  className="w-full relative block"
                  fluid={image.childImageSharp.fluid}
                  alt="Source"
                />
              </AppearOnScroll>
            </div>
          </div>
        )}

        <div
          className={`grid-end-6 ${
            align === `left` ? `grid-start-7` : `grid-start-1`
          } sm:grid-end-10 xs:grid-end-12 sm:grid-start-2 xs:grid-start-1 pl-v3 xs:pl-0 xs:mb-24 pr-v3 xs:pr-0`}
        >
          <h2 className="w-full relative mt-12 whitespace-pre-wrap f3">
            {heading}
          </h2>

          <p className="w-full relative mt-10 xs:mt-6 whitespace-pre-wrap b1">
            {content}
          </p>
        </div>

        {((device && device !== `desktop`) || align === `right`) && (
          <div className="grid-end-6 sm:grid-end-12 pl-v2 xs:pl-0">
            <div className="about-page__bleed relative">
              <AppearOnScroll>
                <Img
                  className="w-full relative block"
                  fluid={image.childImageSharp.fluid}
                  alt="Source"
                />
              </AppearOnScroll>
            </div>
          </div>
        )}
      </article>
    </section>
  );
};

AboutArticle.defaultProps = {
  align: `left`,
  className: ``,
  content: ``,
  heading: ``
};

AboutArticle.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({}).isRequired
    })
  }).isRequired
};

export default AboutArticle;
